import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "./ui/card";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { AlertCircle, Info } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "./ui/alert"
import { Popover, PopoverTrigger, PopoverContent, PopoverAnchor } from "@radix-ui/react-popover";
import React, { ChangeEvent, useEffect, useState } from "react";
import axios from "axios";
import { json } from "stream/consumers";

const apiUrl = process.env.REACT_APP_API_URL;

function LoginCard( props : { onLogin : (idToken : string) => void }) {

    const [newPassRequired, setNewPassRequired] = useState<boolean>(false);
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [emailValue, setEmailValue] = useState<string>("");
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [newPassValue, setNewPassValue] = useState<string>("");
    const [sessionKey, setSessionKey] = useState<string>("");


    useEffect(() => {

        if (!authenticating) {
            return;
        }
        if (!newPassRequired) {
            const input = { username: emailValue, password: passwordValue }

            const authenticate = async () => {
                try {
                    const response = await axios.post(`${apiUrl}authenticate`,
                        JSON.stringify(input),
                        {
                            validateStatus: () => true
                        }
                    )

                    if (response.status === 200 && response.data.challengeName == "NEW_PASSWORD_REQUIRED") {
                        setSessionKey(response.data.session);
                        setErrorMessage("");
                        setNewPassRequired(true);
                    } else if (response.status === 200) {
                        console.log(response.data.tokens.idToken);
                        setErrorMessage("");
                        props.onLogin(response.data.tokens.idToken);
                    } else {
                        let errMessage = response.data.message;
                        if (response.data.details) {
                            errMessage = `${errMessage}: ${response.data.details}`;
                        }
                        setErrorMessage(`${(errMessage || "An unknown error occured")}`);
                    }

                } catch (error) {
                    setErrorMessage(error as string);
                    console.error("Error authenticating:", error);
                }
            };

            authenticate();
            setAuthenticating(false);
        } else {
            const input = { username: emailValue, password: passwordValue, new_password: newPassValue, session: sessionKey }

            const authenticate = async () => {
                try {
                    const response = await axios.post(`${apiUrl}authenticate`,
                        JSON.stringify(input),
                        {
                            validateStatus: () => true
                        }
                    )
                    if (response.status === 200 && typeof response.data.tokens.idToken !== undefined) {
                        setErrorMessage("");
                        props.onLogin(response.data.tokens.idToken);
                    } else {
                        let errMessage = response.data.message;
                        if (response.data.details) {
                            errMessage = `${errMessage}: ${response.data.details}`;
                        }
                        setErrorMessage(`${(errMessage || "An unknown error occured")}`);
                    }

                } catch (error) {
                    setErrorMessage(error as string);
                    console.error("Error authenticating:", error);
                }
            };

            authenticate();
            setNewPassRequired(false);
            setAuthenticating(false);
        }
    }, [authenticating])

    return <div className="static min-h-screen w-full flex items-center justify-center transform -translate-y-1/4">
        <Card className="w-full max-w-2xl">
        <CardHeader>
            <CardTitle>Log In</CardTitle>
        </CardHeader>
        <CardContent>
            { errorMessage ?
            <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>
                    {errorMessage}
                </AlertDescription>
            </Alert>
            : null }
            <div>
                <Label htmlFor="email">Email</Label>
                <Input id="email" value={emailValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.currentTarget.value.toLowerCase()) } placeholder="yourname@email.com"/>
            </div>
            <div>
                <Label htmlFor="password">Password</Label>
                <Input id="password" type="password" onKeyDown={(e : React.KeyboardEvent<HTMLInputElement>) => { if (e.key === "Enter") setAuthenticating(true); }} value={passwordValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setPasswordValue(e.currentTarget.value)}/>
            </div>
            {newPassRequired ? <div>
                <Label htmlFor="newPassword">New Password</Label>
                <Input id="newPassword" type="password" onKeyDown={(e : React.KeyboardEvent<HTMLInputElement>) => { if (e.key === "Enter") setAuthenticating(true); }} value={newPassValue} onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassValue(e.currentTarget.value)}/>
            </div> : null }
            <Popover>
                <PopoverTrigger>
                    <div className="flex text-xs items-center">
                        <div className="flex-1"><Info size={12}/></div>
                        <div className="flex-auto text-left w-64 ml-1 underline decoration-solid"><PopoverAnchor>Password Rules</PopoverAnchor></div>
                    </div>
                </PopoverTrigger>
                <PopoverContent sideOffset={5}>
                    <Card>
                        <CardContent className="mt-3">
                        <p>Passwords must:</p>
                        <ul className="list-disc list-inside">
                            <li>Contain a lowercase character</li>
                            <li>Contain an uppercase character</li>
                            <li>Contain a number</li>
                            <li>Contain a special character</li>
                            <li>Be at least 8 characters long</li>
                        </ul>
                        </CardContent>
                    </Card>
                </PopoverContent>
            </Popover>
        </CardContent>
        <CardFooter className="flex justify-end">
            <Button onClick={() => setAuthenticating(true)}>{newPassRequired ? "Log In" : "Next"}</Button>
        </CardFooter>
    </Card>
    </div>
}

export default LoginCard;
