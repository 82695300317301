import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";

const EULAComponent = () => {
  // State to control the dialog's open/closed state
  const [isOpen, setIsOpen] = useState(false);

  // Lorem ipsum text for the EULA content
  const eulaText = `# SOFTWARE LICENSE AND SERVICE AGREEMENT

BY CLICKING "I AGREE" OR USING THE SERVICE, YOU AGREE TO BE BOUND BY THIS AGREEMENT.

## 1. DEFINITIONS

"Service" means the conflict checking software service and related features provided by American Appworks, LLC ("Company").
"Customer" means the law office or legal entity accessing and using the Service.
"Customer Data" means all data uploaded, stored, or processed through the Service.

## 2. LICENSE AND OWNERSHIP

2.1 Limited License. Customer is granted a non-exclusive, non-transferable, revocable license to access and use the Service solely for internal business purposes.

2.2 Ownership. American Appworks, LLC retains all right, title, and interest in the Service, including all intellectual property rights. No ownership rights are granted to Customer. Customer shall not attempt to modify, reverse engineer, decompile, or create derivative works of the Service.

## 3. SERVICE AVAILABILITY AND DATA

3.1 Service Level Disclaimer. The Service is provided "as is" and "as available" without any guarantees of uptime or availability. American Appworks, LLC makes no warranties that the Service will be uninterrupted, error-free, or available at any particular time.

3.2 Data Loss. American Appworks, LLC is not responsible for any loss, corruption, or deletion of Customer Data for any reason. Customer acknowledges that it is solely responsible for backing up any data entered into the Service.

## 4. DISCLAIMER OF WARRANTIES

THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. AMERICAN APPWORKS, LLC DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.

## 5. LIMITATION OF LIABILITY

5.1 IN NO EVENT SHALL AMERICAN APPWORKS, LLC BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA, USE, OR GOODWILL, ARISING OUT OF OR IN CONNECTION WITH THE SERVICE.

5.2 AMERICAN APPWORKS, LLC'S TOTAL LIABILITY FOR ANY AND ALL CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT SHALL NOT EXCEED THE AMOUNT PAID BY CUSTOMER FOR THE SERVICE IN THE TWELVE (12) MONTHS PRECEDING THE INCIDENT GIVING RISE TO THE CLAIM.

## 6. INDEMNIFICATION

Customer agrees to indemnify and hold harmless American Appworks, LLC from and against any claims, liabilities, damages, losses, and expenses arising from Customer's use of the Service or violation of this Agreement.

## 7. GOVERNING LAW

This Agreement shall be governed by and construed in accordance with the laws of [State/Jurisdiction], without regard to its conflict of law provisions.

## 8. ENTIRE AGREEMENT

This Agreement constitutes the entire agreement between the parties and supersedes all prior agreements regarding the subject matter herein.`;

  return (
    <div className="fixed bottom-0 left-0 w-full p-2 bg-white border-t border-gray-200">
      <p className="text-xs text-gray-600 text-center">
        By using this software, you agree to the terms of our{' '}
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger className="text-blue-600 hover:underline">
            End User License Agreement
          </DialogTrigger>
          <DialogContent className="w-11/12 h-5/6 max-w-4xl">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold">
                End User License Agreement
              </DialogTitle>
            </DialogHeader>
            <ScrollArea className="h-full max-h-[60vh] w-full rounded-md border p-4">
              <p className="text-sm text-gray-700 whitespace-pre-line">
                {eulaText}
              </p>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              <Button onClick={() => setIsOpen(false)}>
                Accept
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </p>
    </div>
  );
};

export default EULAComponent;