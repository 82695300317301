import { ContextMenuTriggerProps } from "@radix-ui/react-context-menu";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuPortal
} from "./ui/context-menu";
import axios from "axios";
import React, { ChangeEvent, Ref, useEffect, useState } from "react";
import {Matter, Contact, ContactInfo} from "src/lib/conflix";
import SheetCell from "./sheetCell";

const apiUrl = process.env.REACT_APP_API_URL;

function MatterRow(props: {
  matter: Matter;
  contact: Contact;
  designation: string;
  info: ContactInfo;
  idToken: string;
  org: string;
  onLoading: (isLoading: boolean) => void;
  onUpdate: () => Promise<void>;
  onError: (err: string) => void;
}) {
  const [editedField, setEditedField] = useState<string>("");
  const [refNumberValue, setRefNumberValue] = useState<string>(
    props.matter.referenceNumber
  );
  const [nameValue, setNameValue] = useState<string>(props.matter.name);

  const onDelete = async () => {
    props.onLoading(true);
    const response = await axios.delete(
      `${apiUrl}matters/${props.matter.matterId}/contacts/${props.contact.contactId}`,
      {
        headers: {
          Authorization: props.idToken,
          "x-orgslug": props.org,
          "Content-Type": "application/json",
        },
        validateStatus: () => true
      }
    );
    if (!(response.status >= 200 && response.status < 300)) {
      let errMessage = response.data.message;
      if (response.data.details) {
          errMessage = `${errMessage}: ${response.data.details}`;
      }
      props.onError(errMessage);
    }
    props.onLoading(false);
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>
        <TableRow className="hover:bg-gray-50">
          <SheetCell field="referenceNumber" matter={props.matter} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="name" matter={props.matter} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="designation" contact={props.contact} matter={props.matter} designation={props.designation} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="firstName" contact={props.contact} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="lastName" contact={props.contact} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="middleName" contact={props.contact} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="companyName" contact={props.contact} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="dateOfBirth" contact={props.contact} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="phone" contact={props.contact} matter={props.matter} info={props.info} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="email" contact={props.contact} matter={props.matter} info={props.info} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
          <SheetCell field="address" contact={props.contact} matter={props.matter} info={props.info} idToken={props.idToken} org={props.org} onLoading={props.onLoading} onUpdate={props.onUpdate} onError={props.onError}/>
        </TableRow>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={onDelete} inset>Delete</ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}

export default MatterRow;
