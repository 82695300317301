import MatterRow from "./matter";
import NewMatter from "./newMatter";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { LoadingSpinner } from "./ui/loading-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

import axios from "axios";
import { title } from "process";
import React, { useEffect, useState } from "react";
import { Contact, Matter, MatterContactLink } from "src/lib/conflix";

const apiUrl = process.env.REACT_APP_API_URL;

function Spreadsheet(props: { idToken: string, org: string }) {
  const [matters, setMatters] = useState<Matter[] | null>(null);
  const [matterContacts, setMatterContacts] = useState<MatterContactLink[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}matter-contacts`,
          {
            headers: {
            "Authorization": props.idToken,
            "x-orgslug": props.org
            }
          }
        );

        setMatterContacts(response.data);
      } catch (error) {
        console.error("Error fetching matters:", error);
      }
    }

    fetchMatters();
  }, [loading]);

  const createRefNumber = () : string => {
    let highestNumber = 0;
    const currentYear = new Date().getFullYear().toString();
    matterContacts?.forEach(link => {
      const matter = link.matter;
      const splitNumber = matter.referenceNumber?.split("-");
      if (splitNumber && splitNumber[0] === currentYear && Number(splitNumber[1]) > highestNumber) {
        highestNumber = Number(splitNumber[1]);
      }
    });

    const newRefNumber = `00${highestNumber + 1}`.slice(Math.min((highestNumber + 1).toString().length - 1, 2));
    return `${currentYear}-${newRefNumber}`;
  }

  const onUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}matter-contacts`,
        {
          headers: {
            "Authorization": props.idToken,
            "x-orgslug": props.org
          }
        }
      );
      setMatterContacts(response.data);
    } catch (error) {
      console.error("Error fetching matters:", error);
    }
    setLoading(false);
  };

  const onLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  }

  const onError = (err: string) => {
    setLoading(false);
    setError(err);
  }

  function getMatterFromId(id: string) : Matter {
    const matter = matters?.find(m => { return m.matterId === id });
    if (matter) {
      return matter;
    } else {
      return { matterId: "none", name: "none", referenceNumber: "0000-000", createdAt: "0000-00-00"}
    }
    
  }

  if (!matterContacts || loading) {
    return <div className="container mx-auto grid justify-items-center">
      <LoadingSpinner size={150} />
      <div>Loading...</div>
    </div>;
  }

  return (
    <Card className="max-w-full overflow-auto mx-auto my-8">
      <CardHeader>
        <CardTitle>Spreadsheet View</CardTitle>
        <CardDescription className="text-red-600">{error}</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-24">Number</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Designation</TableHead>
              <TableHead>First Name</TableHead>
              <TableHead>Last Name</TableHead>
              <TableHead>Middle Name</TableHead>
              <TableHead>Company</TableHead>
              <TableHead>Date of Birth</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Address</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {matterContacts?.map((object) => (
                <MatterRow onError={onError} onUpdate={onUpdate} key={object.matter.matterId} contact={object.contact} matter={object.matter} designation={object.designation} info={object.contactInfo} idToken={props.idToken} org={props.org} onLoading={onLoading}/>
            ))}
            <NewMatter onError={onError} onUpdate={onUpdate} idToken={props.idToken} org={props.org} nextRefNum={createRefNumber()} onLoading={onLoading} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default Spreadsheet;
