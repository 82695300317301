import React, { ChangeEvent, Ref, useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./ui/table";
import { Input } from "./ui/input";
import axios from "axios";
import { error, table } from "console";
import { Contact, ContactInfo, Matter } from "src/lib/conflix";

const apiUrl = process.env.REACT_APP_API_URL;

function SheetCell(
    props: {
        matter?: Matter;
        contact?: Contact;
        info?: ContactInfo;
        designation?: string;
        field: string;
        idToken: string;
        org: string;
        onLoading: (isLoading: boolean) => void;
        onUpdate: () => Promise<void>;
        onError: (err: string) => void;
    }
) {
    function GetInitValue() : string {
        if (props.matter && !props.contact) {
            const prop = Object.entries(props.matter).find((property) => { return property[0] === props.field});
            if (!prop) return "";
            return prop[1];
        } else if (props.contact && !props.matter) {
            const prop = Object.entries(props.contact).find((property) => { return property[0] === props.field});
            if (!prop) return "";
            if (props.field==="dateOfBirth" && prop[1]) {
                console.log("DOB");
                return prop[1].split("T")[0];
            } else {
                console.log("not DOB");
                return prop[1];
            }
        } else if (props.info) {
            const prop = Object.entries(props.info).find((property) => { return property[0] === props.field});
            if (!prop) return "";
            return prop[1].value;
        } else if (props.designation) {
            return props.designation;
        } else {
            console.error("Spreadsheet cell is missing data: did you forget to add a matter or contact parameter?")
            return "";
        }
    }

    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(GetInitValue());

    async function createContactInfo(contactId : string, type : string, value : string) : Promise<string> {
        try {
            const response = await axios.post(
                `${apiUrl}contacts/${contactId}/info`,
                { type, value },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": props.idToken,
                        "x-orgslug": props.org
                    },
                }
            );
                const contactInfoId = response.data.id;
                if (contactInfoId) {
                    return contactInfoId;
                }
                props.onError("No contact info ID returned");
                throw new Error("No contact info id returned");
        } catch (error: any) {
            if (error.message) {
                let errMessage = error.message;
                if (error.details) {
                  errMessage = `${errMessage}: ${error.details}`;
                }
                props.onError(errMessage);
                console.log(error);
              } else {
                console.error("Error creating contact info:", error);
                throw error;
              }
              return new Promise<string>(() => "");
        }
    }

    function format(field : string, fieldValue : string) {
        if (field === "dateOfBirth") {
            return new Date(fieldValue).toISOString();
        }
        return fieldValue;
    }

    const onEditField = async (e: React.FocusEvent<HTMLInputElement>) => {
        props.onLoading(true);

        if (props.matter && !props.contact) {
            type Data = {
                [key: string]: string | undefined
            }

            let data : Data  = {
                name: props.field === "name" ? value : props.matter.name,
                referenceNumber: props.field === "referenceNumber" ? value : props.matter.referenceNumber,
            }

            if (props.field !== "name" && props.field !== "referenceNumber") {
                data[props.field] = value;
            }

            const response = await axios.put(
                `${apiUrl}matters/${props.matter.matterId}`,
                data,
                {
                headers: {
                    Authorization: props.idToken,
                    "x-orgslug": props.org,
                    "Content-Type": "application/json",
                },
                }
            );
        } else if (props.contact && !props.matter) {
            const formattedValue = format(props.field, value);

            const response = await axios.put(
                `${apiUrl}contacts/${props.contact.contactId}`,
                {[props.field]: formattedValue},
                {
                    headers: {
                        Authorization: props.idToken,
                        "x-orgslug": props.org,
                        "Content-Type": "application/json",
                    },
                }
            );
        } else if (props.info && props.contact && props.matter) {
            const fieldKey = props.field as keyof ContactInfo;
            
            if (props.info[fieldKey]) {
                const response = await axios.put(
                    `${apiUrl}contacts/${props.contact.contactId}/info/${props.info[props.field as keyof ContactInfo].id}`,
                    { 
                        value,
                        "type": props.field
                    },
                    {
                        headers: {
                            Authorization: props.idToken,
                            "x-orgslug": props.org,
                            "Content-Type": "application/json",
                        },
                    }
                );
            } else {
                const infoId = await createContactInfo(props.contact.contactId, props.field, value);
                const formattedInfo = Object.fromEntries(Object.entries(props.info).map(([key, value]) => {
                    const newKey = key as keyof ContactInfo;
                    if (!props.info) return [];
                    return ["email","phone","address"].includes(key) ? [key, props.info[newKey].id] : [];
                }))
                const primaryContactInfo = { [props.field]: infoId, ...formattedInfo }
                const response = await axios.put(
                    `${apiUrl}matters/${props.matter.matterId}/contacts/${props.contact.contactId}`,
                    { 
                        primaryContactInfo
                    },
                    {
                        headers: {
                            Authorization: props.idToken,
                            "x-orgslug": props.org,
                            "Content-Type": "application/json",
                        },
                    }
                );
            }

            
        } else if (props.designation && props.matter && props.contact) {
            const response = await axios.put(
                `${apiUrl}matters/${props.matter.matterId}/contacts/${props.contact.contactId}`,
                { 
                    designation: value
                },
                {
                    headers: {
                        Authorization: props.idToken,
                        "x-orgslug": props.org,
                        "Content-Type": "application/json",
                    },
                }
            );
        }
        
        setEditing(false);
        props.onLoading(false);
        await props.onUpdate();
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          e.currentTarget.blur();
        }
    };

    const onSelect = (e: React.MouseEvent<HTMLTableCellElement>) => {
        setEditing(true);
    };


    return (
        <TableCell
            className="font-medium"
            id="referenceNumber"
            onDoubleClick={onSelect}
        >
            {editing ? (
                <Input type={props.field === "dateOfBirth" ? "date" : "text"}
                value={value}
                onBlur={onEditField}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setValue(e.currentTarget.value)
                }
                onKeyDown={handleEnter}
                autoFocus
                />
            ) : (
                value
            )}
        </TableCell>
    );
}

export default SheetCell;