import MatterRow from "./matter";
import { Input } from "./ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "./ui/command"
import axios from "axios";
import React, { KeyboardEvent, SetStateAction, useEffect, useState } from "react";
import {Matter, Contact, MatterContactLink} from "src/lib/conflix";
import { Popover } from "@radix-ui/react-popover";
import { PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from "./ui/button";
import { CommandLoading } from "cmdk";
import { buffer } from "stream/consumers";

const apiUrl = process.env.REACT_APP_API_URL;

function NewMatter(
  props: { 
    idToken: string, 
    org: string, 
    nextRefNum: string, 
    onLoading: (isLoading: boolean) => void, 
    onError: (err: string) => void;  
    onUpdate: () => Promise<void>;
  }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [matterInfo, setMatterInfo] = useState<Matter>({
    matterId: "",
    referenceNumber: props.nextRefNum,
    name: "",
    createdAt: "",
  });
  const [contactInfo, setContactInfo] = useState<Contact>({
    contactId: "",
    firstName: "",
    lastName: "",
    createdAt: "",
    createdBy: "",
    companyName: "",
    middleName: "",
    dateOfBirth: ""
  });
  const [designation, setDesignation] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [noMatter, setNoMatter] = useState<boolean>(false);
  const [noContact, setNoContact] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [matters, setMatters] = useState<Matter[] | null>(null);
  const [selectedMatter, setSelectedMatter] = useState<Matter | null>(null);

  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}matters`,
          {
            headers: {
              "Authorization": props.idToken
            }
          }
        );

        setMatters(response.data);
      } catch (error) {
        console.error("Error fetching matters:", error);
      }
    }

    fetchMatters();
  }, [loading])

  async function createMatter(name : string, referenceNumber : string) : Promise<string> {
    try {
      const response = await axios.post(
        `${apiUrl}matters`,
        { name, referenceNumber },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": props.idToken,
            "x-orgslug": props.org
          },
        }
      );  
        const matterId = response.data.matterId;
        if (matterId) {
          return matterId;
        }
        props.onError("No matter ID returned")
        throw new Error("No matter id returned");
    } catch (error : any) {
      if (error.message) {
        let errMessage = error.message;
        if (error.details) {
          errMessage = `${errMessage}: ${error.details}`;
        }
        props.onError(errMessage);
        console.log(error);
      } else {
        console.error("Error creating matter:", error);
        throw error;
      }
      return new Promise<string>(() => "");
    }
  }

  async function createContact(firstName : string, lastName : string, middleName : string, companyName : string, dateOfBirth : string) : Promise<string> {
    let data = {}
    if (dateOfBirth) {
      data = { firstName, lastName, middleName, companyName, dateOfBirth }
    } else {
      data = { firstName, lastName, middleName, companyName }
    }
    try {
      const response = await axios.post(
        `${apiUrl}contacts`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": props.idToken,
            "x-orgslug": props.org
          },
        }
      );
      const contactId = response.data.contactId;
      if (contactId) {
        return contactId;
      }
      props.onError("No contact ID returned");
      throw new Error("No contact ID returned");
    } catch (error: any) {
      if (error.message) {
        let errMessage = error.message;
        if (error.details) {
          errMessage = `${errMessage}: ${error.details}`;
        }
        props.onError(errMessage);
        console.log(error);
      } else {
        console.error("Error creating contact:", error);
        throw error;
      }
      return new Promise<string>(() => "");
    }
  }

  async function createContactInfo(contactId : string, type : string, value : string) : Promise<string> {
    try {
      const response = await axios.post(
        `${apiUrl}contacts/${contactId}/info`,
        { type, value },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": props.idToken,
            "x-orgslug": props.org
          },
        }
      );
      const contactInfoId = response.data.id;
      if (contactInfoId) {
        return contactInfoId;
      }
      props.onError("No contact info ID returned")
      throw new Error("No contact info id returned");
    } catch (error: any) {
      if (error.message) {
        let errMessage = error.message;
        if (error.details) {
          errMessage = `${errMessage}: ${error.details}`;
        }
        props.onError(errMessage);
        console.log(error);
      } else {
        console.error("Error creating contact info:", error);
        throw error;
      }
      return new Promise<string>(() => "");
    }
  }

  async function createMatterContactLink(matterId : string, contactId : string, primaryContactInfo: {email: string, phone: string, address: string}) : Promise<string> {
    try {
      const response = await axios.post(
        `${apiUrl}matters/${matterId}/contacts/${contactId}`,
        { 
          primaryContactInfo,
          designation
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": props.idToken,
            "x-orgslug": props.org
          },
        }
      );
      const data = response.data;
      if (data) {
        return data;
      }
      props.onError("No matter-contact link data returned")
      throw new Error("No matter-contact link data returned");
    } catch (error: any) {
      if (error.message) {
        let errMessage = error.message;
        if (error.details) {
          errMessage = `${errMessage}: ${error.details}`;
        }
        props.onError(errMessage);
        console.log(error);
      } else {
        console.error("Error creating matter-contact link:", error);
        throw error;
      }
      return new Promise<string>(() => "");
    }
  }

  const handleEnter = async (e: React.KeyboardEvent<HTMLInputElement> ) => {
    if (e.key === "Enter") {
      if (!selectedMatter && (!matterInfo.name || !matterInfo.referenceNumber)) {
        setNoMatter(true);
        props.onError("A matter must have a reference number and title.")
        return;
      }
      setNoMatter(false);
      if ((!contactInfo.firstName || !contactInfo.lastName) && !contactInfo.companyName) {
        setNoContact(true);
        props.onError("A contact must have a first and last name or a company name.")
        return;
      }
      setNoContact(false);
      props.onError("");
      setLoading(true);
      props.onLoading(true);
      const matterId = selectedMatter ? selectedMatter.matterId : await createMatter(matterInfo.name, matterInfo.referenceNumber);
      const dobIso = contactInfo.dateOfBirth ? new Date(contactInfo.dateOfBirth).toISOString() : "";
      const contactId = await createContact(contactInfo.firstName, contactInfo.lastName, contactInfo.middleName, contactInfo.companyName, dobIso);
      const phoneId = phone ? await createContactInfo(contactId, "phone", phone) : "";
      const emailId = email ? await createContactInfo(contactId, "email", email) : "";
      const addressId = address ? await createContactInfo(contactId, "address", address) : "";
      await createMatterContactLink(matterId, contactId, {email: emailId, phone: phoneId, address: addressId});
      
      setMatterInfo({
        matterId: "",
        referenceNumber: props.nextRefNum,
        name: "",
        createdAt: "",
      });
      setContactInfo({
        contactId: "",
        firstName: "",
        lastName: "",
        createdAt: "",
        createdBy: "",
        companyName: "",
        middleName: "",
        dateOfBirth: ""
      });
      setPhone("");
      setEmail("");
      setAddress("");
      setDesignation("");

      setLoading(false);
      props.onLoading(false);
      await props.onUpdate();
    }
  }

  const handleNext = (e: React.KeyboardEvent<HTMLInputElement> ) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
}

  const handleChange = (value: string, matterField?: keyof Matter, contactField?: keyof Contact) => {
    if (matterField) {
      const matterClone : Matter = JSON.parse(JSON.stringify(matterInfo));
      matterClone[matterField] = value;
      setMatterInfo(matterInfo => matterClone);
    } else if (contactField) {
      const contactClone : Contact = JSON.parse(JSON.stringify(contactInfo));
      contactClone[contactField] = value;
      setContactInfo(contactInfo => contactClone);
    }
  }

  return (
      <TableRow className="hover:bg-gray-50">
          <TableCell className="font-medium">
          {loading ? <div>Loading...</div> : 
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button variant="outline" className={noMatter ? "border border-red-600" : ""}>{selectedMatter ? selectedMatter.referenceNumber : matterInfo.referenceNumber}</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <Command loop>
                    <CommandInput placeholder="Find or Create Matter" onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e.currentTarget.value, "referenceNumber"); } } />
                    <CommandList>
                      <CommandEmpty>Matter not found.</CommandEmpty>
                      <CommandItem
                        key={matterInfo.referenceNumber}
                        onSelect={() => {
                          setSelectedMatter(null);
                          setOpen(false);
                        }}
                      >
                        {`Create Matter Number "${matterInfo.referenceNumber}"`}
                      </CommandItem>
                      { matters?.map((matter) => (
                        <CommandItem
                          key={matter.referenceNumber}
                          value={matter.referenceNumber}
                          onSelect={(value) => {
                            setSelectedMatter(matter);
                            setOpen(false);
                          }}
                          onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => { e.preventDefault(); }}
                        >
                          {matter.referenceNumber}
                        </CommandItem>
                      )) }
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover> }
          </TableCell>
          <TableCell >
            {loading ? <div>Loading...</div> : <Input className={noMatter ? "border border-red-600" : ""} placeholder="Title" value={selectedMatter ? selectedMatter.name : matterInfo.name} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,"name")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input placeholder="Designation" value={designation} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDesignation(e.currentTarget.value)} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input className={noContact ? "border border-red-600" : ""} placeholder="First Name" value={contactInfo.firstName} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,undefined,"firstName")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input className={noContact ? "border border-red-600" : ""} placeholder="Last Name" value={contactInfo.lastName} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,undefined,"lastName")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input placeholder="Middle Name" value={contactInfo.middleName} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,undefined,"middleName")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input className={noContact ? "border border-red-600" : ""} placeholder="Company" value={contactInfo.companyName} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,undefined,"companyName")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input type="date" placeholder="Date of Birth" value={contactInfo.dateOfBirth} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value,undefined,"dateOfBirth")} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input placeholder="Phone" value={phone} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.currentTarget.value)} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input placeholder="Email" value={email} onKeyDown={handleNext} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)} /> }
          </TableCell>
          <TableCell>
              {loading ? <div>Loading...</div> : <Input placeholder="Address" value={address} onKeyDown={handleEnter} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.currentTarget.value)} /> }
          </TableCell>
      </TableRow>
  );
}

export default NewMatter;
